@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,600;1,900&display=swap');

@import url('https://rsms.me/inter/inter.css');
html {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');

@font-face {
  font-family: 'Franklin Gothic Heavy Regular';
  src: local('Franklin Gothic Heavy Regular'),
    url('./fonts//Franklin\ Gothic\ Heavy\ Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Franklin Gothic';
  src: local('Franklin Gothic'),
    url('./fonts/FranklinGothic/FranklinGothicRegular/Franklin\ Gothic\ Regular.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Franklin Gothic Condensed';
  src: local('Franklin Gothic Condensed'),
    url('./fonts/FranklinGothic/FranklinGothicCondensed/Franklin\ Gothic\ Condensed.otf')
      format('opentype');
}

@font-face {
  font-family: 'Sprite';
  src: local('Sprite'),
    url('./fonts/Sprite/SpriteGraffiti-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Copperlate';
  src: local('Copperlate'),
    url('./fonts/Copperplate-Gothic-Std-32-BC.ttf') format('truetype');
}

@font-face {
  font-family: 'Chinook';
  src: local('Chinook'), url('./fonts/Chinook-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Handfine';
  src: local('Handfine'), url('./fonts/Handfine.otf') format('opentype');
}

@font-face {
  font-family: 'Handfine';
  src: local('Handfine'), url('./fonts/Handfine.otf') format('opentype');
}

@font-face {
  font-family: 'Nue-Gothic';
  src: local('Nue-Gothic'), url('./fonts/Nue-Gothic.ttf') format('truetype');
}

@font-face {
  font-family: 'Valenttiena';
  src: local('Valenttiena'), url('./fonts/Valenttiena.ttf') format('truetype');
}

@font-face {
  font-family: 'corp';
  src: local('corp'), url('./fonts/corp.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
#main {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Franklin Gothic';
  background-image: url('../public/mosaic.jpg');
  background-size: 200px;
  --main-yellow: #ffea6f;
  --main-red: #e9463a;
  --main-blue: #444f9d;
  --main-orange: #fdc76a;
}

footer {
  font-family: 'Chinook';
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: calc(50% - 100px);
  top: 84vh;
  img {
    width: 40px;
  }
}

.force-position {
  position: unset !important;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.text-box {
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  input {
    height: 2.5rem;
    border-radius: 5px;
    -webkit-appearance: none;
    outline: none;
    box-shadow: none !important;
    border: none;
    padding: 1rem;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 1s ease 0.3s forwards;
}
.header-container {
  position: absolute;
  z-index: 1000;
}
.menu-wrapper {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
  ul {
    padding-left: 10px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 40px;
  align-items: center;
  z-index: 1000;
}

header p {
  font-weight: bold;
  font-size: 0.8rem;
  transition: all 1.2s ease-out;
}

header p.hidden {
  opacity: 0;
}

button {
  background-color: var(--main-red);
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: bolder;
  pointer-events: all;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: inset 0 0 0 0.09px black;

  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px 30px;
}

button:hover {
  box-shadow: inset 250px 0 0 0 var(--main-blue);
  transform: scale(1.1);
}

/* CUSTOMIZER */

.customizer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 25px;
}

.customizer span {
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
}

.customizer span svg {
  width: 24px;
  padding-right: 5px;
}

.color-options {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}

.circle:hover {
  transform: scale(1.2);
  cursor: pointer;
}

/* FONTS */
.font-times {
  font-family: 'Times New Roman', Times, serif;
}

.font-arial {
  font-family: Arial, Helvetica, sans-serif;
}
.font-sprite {
  font-family: 'Sprite';
}
.font-chinook {
  font-family: 'Chinook';
}
.font-handfine {
  font-family: 'Handfine';
}
.font-nue-gothic {
  font-family: 'Nue-Gothic';
}
.font-copperlate {
  font-family: 'Copperlate';
}
.font-valenttiena {
  font-family: 'Valenttiena';
}
.font-corp {
  font-family: 'corp';
}

/* FONT SIZES */
.font-10px {
  font-size: 10px;
}

.font-12px {
  font-size: 12px;
}

.font-14px {
  font-size: 14px;
}

.font-16px {
  font-size: 16px;
}
.font-20px {
  font-size: 20px;
}
.font-24px {
  font-size: 24px;
}
.font-28px {
  font-size: 28px;
}
.font-40px {
  font-size: 40px;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  padding: 1rem;
  cursor: pointer;
  z-index: 1000;
}

.tab {
  background-color: var(--main-blue);
  padding: 20px 30px;
  border-radius: 50px;
  color: white;
  width: 150px;
  text-align: center;
}

.inverted-tab {
  background-color: var(--main-red);
}

.selected-tab {
  background-color: var(--main-red);
}

.selected-tab-blue {
  background-color: var(--main-blue);
}

.home-wrapper {
  height: 100vh;
  overflow: hidden;
}

.lato {
  font-family: 'Lato', sans-serif;
}
.patua {
  font-family: 'Patua One', cursive;
}
.yeseva {
  font-family: 'Yeseva One', cursive;
}

.home-content {
  background-image: url('../public/mosaic.jpg');
  background-size: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.photo-box {
  position: relative;
}

.photo-portrait {
  width: 20rem;
  height: 25rem;
  background-color: white;
  background-image: url('../public/foto_chicos.jpeg');
  background-size: cover;
  border: 6px solid white;
  transform: rotate(2deg) !important;
}
.photo-portrait-text {
  font-family: 'Sprite';
  position: absolute;
  bottom: -8rem;
  text-shadow: 2px 1px 0px white;
  font-size: 67px;
  transform: rotate(-5deg);
  h3 {
    margin: 0;
  }
}
.business {
  font-family: 'Copperlate';
  transform: rotate(0) !important;
}

.photo-2 {
  background-image: url('../public/foto_chicos2.jpeg') !important;
  background-position-x: center;
  transform: rotate(-2deg) !important;
  position: relative;
}

.historia-wrapper {
  background-image: url('../public/paper.PNG');
  background-size: cover;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}

.presupuesto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  p {
    font-size: 15px;
  }
}

.presupuesto-wrapper {
  background-image: url('../public/paper.PNG');
  background-size: cover;
  padding-top: 24rem;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 2rem;
}

.presupuesto-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.row {
  display: flex;
  flex-direction: row;
}

.contacto-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  h3 {
    text-align: center;
  }
  p {
    font-weight: 300;
    font-size: 14px;
  }
}

.presupuesto-text {
  position: absolute;
  font-family: 'Handfine';
  font-weight: 300;
  font-size: 26px !important;
  right: -120px;
  transform: rotate(-8deg);
}

.price-table {
  border-collapse: collapse;
  /* background-color: #fcbad3; */
}

.price-table td {
  border: 2px solid black;
  padding: 0.625rem 3.125rem;
}

.price-table tr {
  height: 60px;
}

.productos-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.productos-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.presupuesto-textbox {
  display: flex;
  flex-direction: column;
  position: relative;
  color: black;
  padding: 10px 30px;
  h5,
  ul {
    font-size: 16px;
    font-family: 'Arial';
    margin-top: 0;
  }
}
.productos-text {
  display: flex;
  flex-direction: column;
  position: relative;

  h1 {
    font-family: 'Franklin Gothic Condensed';
    font-size: 2.5rem;
    margin: 0;
  }
  h2 {
    font-family: 'Copperlate';
    font-size: 1.875rem;
    margin: 0;
  }
  h3 {
    font-family: 'Handfine';
    margin: 0;
    font-size: 1.625rem;
    position: absolute;
    bottom: 0;
    right: -85px;
    transform: rotate(-8deg);
  }
}

.product-image {
  overflow: hidden;
  width: 18.75rem;
  height: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
}

.remera {
  img {
    width: 80% !important;
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100vh;
}
.contacto-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 0px 10rem;
  div {
    padding: 3rem;
    color: white;
    background-color: var(--main-blue);
    h1 {
      margin-top: 0;
    }
  }
  p {
    text-align: center;
  }
  a {
    font-weight: 800;
    color: white;
  }
}
.contacto-photo {
  position: absolute;
  bottom: 0;
  right: 0;
  background-image: url('../public/foto_chicos7.jpeg');
  background-size: 200px;
  width: 12.5rem;
  height: 14.5rem;
  background-position-y: -70px;
  transform: rotate(-4deg);
  border: 10px solid #d0ebc8;
  border-radius: 1px;
  -webkit-box-shadow: 2px 2px 18px -7px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 2px 2px 18px -7px rgba(0, 0, 0, 0.48);
  box-shadow: 2px 2px 18px -7px rgba(0, 0, 0, 0.48);
}
.rainbow {
  background-image: url('../public/rainbow.webp');
  border: none;
  background-size: unset;
  background-position: unset;
  bottom: 9rem;
  right: 1rem;
  transform: rotate(-30deg);
  width: 264px;
  box-shadow: none;
}

/* COPIED */

h1 {
  text-align: center;
  margin-top: 50px;
}

.container {
  width: 90%;
  height: 500px;
  margin: 25px auto;
  background-color: #fff;
  display: flex;
  z-index: 3000;
  position: relative;
  border-radius: 5px;
  -webkit-box-shadow: 2px 2px 18px -7px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 2px 2px 18px -7px rgba(0, 0, 0, 0.48);
  box-shadow: 2px 2px 18px -7px rgba(0, 0, 0, 0.48);
}

.container .leftside,
.container .rightside {
  flex: 1;
  height: 100%;
}

.container .leftside {
  background-color: var(--main-blue);
  display: flex;
  height: auto;
}

/* leftside > content */
.leftside .content {
  flex: 1;
  color: #fff;
  padding: 25px;
  display: flex;
  flex-direction: column;
}

button.h1 {
  font-size: 32px;
  font-weight: 600;
}

button.h3 {
  font-size: 24px;
  font-weight: 600;
}

button.h6 {
  font-size: 14px;
  font-weight: 500;
}

/* container > rightside */
.container .rightside {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rightside .btns-flex {
  width: 100%;
  display: flex;
}

.rightside button {
  padding: 14px;
  margin: 10px;
  width: fit-content;
  height: 30px;
  outline: none;
  border: none;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

button.download-btn {
  border: 2px solid var(--main-red);
  color: var(--main-red);
  background-color: #fff;
}

.rightside .downloadable-area {
  width: 100%;
  flex-grow: 1;
  min-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadable-area .main-img-div {
  max-width: 80%;
  /*max-height: 100%; */
  /* width: 40rem; */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-img-div img {
  max-width: 100%;
  max-height: 100%;
  width: 90%;
  /* width: 40rem;
  height: auto; */
}

.bigger-clothes {
  img {
    width: 65% !important;
  }
}

.drop-div {
  position: absolute;
  width: 30%;
  height: 25%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.drop-div.Remera {
  top: 24%;
  /* left: 131px; */
}

.drop-div.Buzo,
.drop-div.Buzo_redondo,
.drop-div.Campera {
  top: 24%;
  right: 37%;
}

.drop-div img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

/* #GENERAL STYLES */
.w-100 {
  width: 100%;
}
.d-flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}

.row {
  flex-direction: row;
}
.column {
  flex-direction: column;
}
.photos-wrapper {
  display: flex;
  align-items: center;
  gap: 13rem;
}
.historia-main-content {
  width: 50%;
}
.historia-photo {
  width: 15rem;
}

.historia-content {
  justify-content: space-between;
}
.photo-right {
  display: flex;
  align-items: self-end;
  justify-content: flex-end;
}
.historia-photo-content-left {
  background-image: url('../public/foto_chicos3.jpeg');
  background-size: 200px;
  width: 12.5rem;
  height: 12.5rem;
  background-position-y: -22px;
  transform: rotate(-8deg);
  border: 6px solid rgb(242, 240, 236);
  border-radius: 1px;
  -webkit-box-shadow: 2px 2px 18px -7px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 2px 2px 18px -7px rgba(0, 0, 0, 0.48);
  box-shadow: 2px 2px 18px -7px rgba(0, 0, 0, 0.48);
}

.historia-photo-content-right {
  background-image: url('../public/foto_chicos4.jpeg');
  background-size: 200px;
  width: 12.5rem;
  height: 12.5rem;
  background-position-y: -70px;
  transform: rotate(8deg);
  border: 6px solid rgb(242, 240, 236);
  border-radius: 1px;
  -webkit-box-shadow: 2px 2px 18px -7px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 2px 2px 18px -7px rgba(0, 0, 0, 0.48);
  box-shadow: 2px 2px 18px -7px rgba(0, 0, 0, 0.48);
}

.about-us {
  h3 {
    color: var(--main-red);
    text-align: center;
    font-size: 20px;
    margin-bottom: 35px;
    font-family: 'Franklin Gothic Condensed';
  }
}

.compromise {
  h3 {
    color: var(--main-red);
    text-align: center;
    font-size: 20px;
    margin: 35px 0px;
    font-family: 'Franklin Gothic Condensed';
  }
  p {
    font-family: 'Arial';
  }
}

/* #MODAL */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 1001;
  border-radius: 8px;
  width: 70%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close-button {
  z-index: 5;
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-content {
  margin-top: 30px;
}

/* FONT COLOR */
.font-EFBD4E {
  color: #efbd4e;
}

.font-ccc {
  color: #ccc;
}
.font-80C670 {
  color: #80c670;
}
.font-726DE8 {
  color: #726de8;
}
.font-EF674E {
  color: #ef674e;
}
.font-353934 {
  color: #353934;
}

/* #MEDIA QUERYS */
@media screen and (max-width: 800px) {
  .home-wrapper {
    width: 100%;
    overflow: hidden;
  }
  .home-content {
    padding-top: 10rem;
    height: 100%;
    flex-direction: column;
  }
  .productos-wrapper {
    padding-top: 20rem;
  }
  .historia-wrapper {
    padding-top: 10rem;
  }
  .productos-content {
    padding-top: 10rem !important;
  }
  .photo-left {
    margin-right: 20px;
  }
  .container {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
  .leftside .content {
    padding: 18px 25px;
  }
  .customizer {
    margin-bottom: 0;
  }
  .section--container {
    margin-left: 15vw;
  }

  .section--container h1 {
    font-size: 8rem;
    letter-spacing: -6px;
    line-height: 6rem;
  }

  .color-options {
    /* position: absolute;
    top: 8px;
    left: calc(50% - 120px); */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .clothes-color {
    top: 222px;
    left: 0;
  }
  .text-box {
    width: 100%;
  }

  .header-wrapper {
    flex-wrap: wrap;
  }

  .productos-images {
    flex-direction: column;
  }
  .product-image {
    height: 17rem;
  }
  .contacto-wrapper {
    padding-top: 15rem;
    padding: 0;
    div {
      padding: 3rem;
    }
  }
  .contacto-photo {
    display: none;
    bottom: -10rem;
  }
  .rainbow {
    display: inline;
    bottom: 0;
  }
  .presupuesto-wrapper {
    padding-top: 42rem;
  }
  .presupuesto-content {
    flex-direction: column;
    padding-bottom: 5rem;
  }
  .presupuesto-text {
    display: none;
  }
  .photos-wrapper {
    flex-direction: column;
    gap: 5rem;
  }

  ∫ .support--content {
    top: -10%;
    left: 0;
  }
}
